import './App.css';
import BgcForm from './components/BgcForm';
import { Form } from './components/Form';

function App() {
  return (
    <div className="App">
<BgcForm/>
    </div>
  );
}

export default App;
